import { datadogRum } from "@datadog/browser-rum";
const init = () => {
  datadogRum.init({
    applicationId: "11a6cd3e-9d39-4a41-86a5-0b6dfbb6071e",
    clientToken: "pub266d06fc1ef8851369ce1c4645b48c54",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: "mt-front",
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 75,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};
export default init;
