import React, { useEffect, useState } from "react";
import thirdPartiesAPI from "../../../_api/_thirdPartiesAPI";
import useFetch from "../../../hooks/useFetch";
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  OutlinedInput,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { CheckCircle } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../../../hooks/useDebounce";
import { useTranslation } from "react-i18next";

const ShareThirdList: React.FC<any> = ({
  id,
  onLoad,
  onSelect,
  selected,
  itemsPerPage = 10,
}) => {
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1);
  const [needle, setNeedle] = useState("");
  const debouncedSearchTerm = useDebounce(needle, 500);
  const itemsNum = itemsPerPage ?? 10;
  const { data, loading } = useFetch(
    thirdPartiesAPI.get({
      ...(id && { "candidate.id": id }),
      page,
      itemsPerPage: itemsNum,
      needle: debouncedSearchTerm,
      // active: true,
    })
  );
  const { list, totalItems } = handleData(data);

  const handleSelect = (id: any) => {
    onSelect(id);
  };

  useEffect(() => {
    onLoad(loading);
  }, [loading, onLoad]);

  return (
    <div>
      {loading ? (
        <ListLoading itemsPerPage={itemsPerPage} />
      ) : (
        <>
          <OutlinedInput
            sx={{ width: "100%" }}
            id="outlined-benef-search"
            placeholder={t("autocomplete.placeholder.employee_example")}
            value={needle}
            onChange={(e) => setNeedle(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <List>
            {list.map((el: any) => (
              <ListItemButton onClick={() => handleSelect(el.id)} key={el.id}>
                <Typography>
                  {el.fullName}
                  <Typography component="span" sx={{ color: "grey!important" }}>
                    {el.company && ` - ${el.company}`}
                  </Typography>
                </Typography>
                {el.id === selected && (
                  <Box sx={{ ml: "auto" }}>
                    <CheckCircle color="success" />
                  </Box>
                )}
              </ListItemButton>
            ))}
            {list.length === 0 && (
              <Typography textAlign="center">
                {t("third_party.none")}
              </Typography>
            )}
          </List>
        </>
      )}

      {totalItems > itemsPerPage && (
        <Box display="center" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Box>
      )}
    </div>
  );
};

export default ShareThirdList;

const handleData = (data: any) => {
  return {
    list: (data?.["hydra:member"] ?? []).map((el: any) => ({
      ...el,
      fullName: `${el?.person?.firstName} ${el?.person?.lastName}`,
      initials:
        el?.person?.firstName.charAt(0) + el?.person?.lastName?.charAt(0),
      company: el?.person?.company?.name,
    })),
    totalItems: data?.["hydra:totalItems"] ?? 0,
  };
};

const ListLoading: React.FC<any> = ({ itemsPerPage }) => {
  const list = Array.from(Array(itemsPerPage ?? 10).keys());
  return (
    <>
      <Skeleton sx={{ height: "53px", width: "100%" }} variant="rectangular" />
      <List>
        {list.map((el: any) => (
          <ListItem key={el}>
            <Skeleton variant="rectangular" height="21px" width="100%" />
          </ListItem>
        ))}
      </List>
      <PaginationSkeleton />
    </>
  );
};

const PaginationSkeleton = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2, gap: 1 }}
    >
      <ArrowBackIosRoundedIcon
        sx={{ fontSize: "13px", opacity: 0.5, fontWeight: "bold" }}
      />
      <Skeleton variant="circular" width="32px" height="32px" />
      <Skeleton
        variant="circular"
        width="32px"
        height="32px"
        sx={{ animationDelay: "0.75s" }}
      />
      <Skeleton
        variant="circular"
        width="32px"
        height="32px"
        sx={{ animationDelay: "1s" }}
      />
      <ArrowForwardIosRoundedIcon
        sx={{ fontSize: "13px", opacity: 0.5, fontWeight: "bold" }}
      />
    </Box>
  );
};
