import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../../_utils/modalStyle";

const ActionButton = styled(Button)`
  font-size: 14px;
  padding: 8px 22px;
`;

const AdvisorRemindModal: React.FC<any> = ({
  open,
  close,
  remindUser,
  loading,
  handleRemind,
}) => {
  const handleConfirm = () => {
    remindUser.forEach((person: any) => {
      handleRemind(person);
    });
    open(false);
  };

  const { t } = useTranslation("common");

  return (
    <Modal open={open}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", sm: "500px" },
        }}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <IconButton
            disabled={loading}
            onClick={close}
            sx={{ ml: "auto", mt: 1, mr: 1 }}
            aria-label="close"
          >
            <HighlightOff />
          </IconButton>
        </Box>
        <CardContent sx={{ mt: -6, textAlign: "center" }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t("admin.users.remind", { count: remindUser.length })}
          </Typography>
          <Typography marginTop={2} variant="subtitle1">
            {t("admin.users.ask_remind", { count: remindUser.length })}
          </Typography>
        </CardContent>

        <CardActions
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mt: -1,
            mb: 5,
          }}
        >
          <ActionButton
            disabled={loading}
            onClick={handleConfirm}
            variant="contained"
          >
            {t("admin.users.remind_action", { count: remindUser.length })}
          </ActionButton>
          <ActionButton disabled={loading} onClick={close} variant="outlined">
            {t("btn.cancel")}
          </ActionButton>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default AdvisorRemindModal;
